// App.js
import React from "react";
import MonitorFrame from "./components/MonitorFrame";
import styles from "./App.module.css";
import "./styles/global.css";

function App() {


  return (
    <div className={styles.appContainer}>
      {/* Only MonitorFrame here */}
      <MonitorFrame />
    </div>
  );
}

export default App;
