// SVGFilters.js
import React, { useEffect, useState } from "react";

// Ease-out cubic function: f(t) = 1 - (1 - t)^3
const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

const SVGFilters = ({ baseFrequency = 0.05, initialScale = 1000, finalScale = 5, duration = 3000 }) => {
  const [scale, setScale] = useState(initialScale);
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    let start = null;

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const progressRatio = Math.min(progress / duration, 1);
      const easedValue = easeOutCubic(progressRatio);
      const currentScale = initialScale + (finalScale - initialScale) * easedValue;
      setScale(currentScale);
      
      if (progress < duration) {
        requestAnimationFrame(animate);
      } else {
        setAnimationComplete(true);
      }
    };

    requestAnimationFrame(animate);
  }, [initialScale, finalScale, duration]);

  useEffect(() => {
    if (animationComplete) {
      const fluctuate = () => {
        setScale(5 + Math.random()); // Randomly fluctuates between 7 and 10
      };
      
      const interval = setInterval(fluctuate, Math.random() * 1000); // Change value every 500ms
      return () => clearInterval(interval);
    }
  }, [animationComplete]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      style={{ position: "absolute", width: 0, height: 0 }}
    >
      <filter id="crtCurved" filterUnits="objectBoundingBox" x="0" y="0" width="1" height="1">
        <feTurbulence 
          type="fractalNoise" 
          baseFrequency={baseFrequency} 
          numOctaves="6" 
          result="noise"
        />
        <feDisplacementMap 
          in="SourceGraphic" 
          in2="noise" 
          scale={scale} 
          xChannelSelector="R" 
          yChannelSelector="G" 
        />
      </filter>
    </svg>
  );
};

export default SVGFilters;
