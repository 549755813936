import React, { useEffect } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import SVGFilter from "./SVGFilter";
import styles from "./WhatImDoingNow.module.css";

const WhatImDoingNow = () => {
  // Detect Safari and add a class for potential fixes
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.documentElement.classList.add("safari");
    }
  }, []);

  const { scrollY } = useScroll();

  // Create vertical translation and rotation effects
  const y = useTransform(scrollY, [0, 300], [0, 50]);
  const rotate = useTransform(scrollY, [0, 900], [0, 5]);

  // Smooth out the animations
  const smoothY = useSpring(y, { damping: 20, stiffness: 100 });
  const smoothRotate = useSpring(rotate, { damping: 0, stiffness: 100 });

  return (
    <>
      {/* Render the SVG filter definitions */}
      <SVGFilter />

      <motion.div
        className={styles.container}
        style={{ y: smoothY, rotate: smoothRotate }}
      >
        <h2 className={styles.pageTitle}>What I'm Doing Now</h2>
        <section className={styles.section}>
          <h3>Overview</h3>
          <p>
            Thumbsy is a product recommendation platform that uses LLM search to tailor suggestions based on your personal taste profile. The system gathers insights from your search behaviors and preferences to provide recommendations that align more closely with what you’re looking for.
          </p>
          <p>
            Unlike conventional recommendation systems that rely on broad algorithms, Thumbsy refines the search process by understanding specific user preferences. This practical approach reduces the clutter of irrelevant suggestions, making it easier to discover products that suit your unique style and needs. The design and functionality focus on delivering a straightforward, user-friendly experience that enhances everyday shopping through precise, personalized recommendations.
          </p>
          <p>
            Visit the live site:{" "}
            <a
              href="https://thumbsy.xyz"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Thumbsy.xyz
            </a>
          </p>
        </section>
        <section className={styles.section}>
          <h3>Timeline</h3>
          <p>Thumbsy started in late 2024 as a simple barcode scanner that would scan the barcode of a product and return a list of similar products. It has since evolved into a full-fledged product recommendation platform that uses LLM search to tailor suggestions based on your personal taste profile.</p>
        </section>
        <section className={styles.section}>
          <h3>Skills</h3>
          <p>Figma, React, CSS, Python, FastAPI, PostgreSQL</p>
        </section>
        <section className={styles.section}>
          <h3>Team</h3>
          <p>
        Product Design Engineer, Full Stack Engineer, Front End Engineer
          </p>
        </section>
        <section className={styles.section}>
          <h3>Research</h3>
          <h4>The Problem: Current Recommendation Systems</h4>
          <p>
            Current recommendation systems often fall short by relying on generic algorithms that miss the nuances of individual tastes. Consumers are overwhelmed by irrelevant suggestions that don’t match their personal style or needs, leading to a frustrating shopping experience. Thumbsy was developed to address this gap by utilizing LLM search to capture the intricacies of your preferences, ensuring every recommendation is as unique as you are.
          </p>
        </section>
       
        <section className={styles.section}>
          <h3>Conclusions</h3>
          <p>
            This is a work in progress with many next steps to be taken as we refine and expand the platform.
          </p>
        </section>

      </motion.div>
    </>
  );
};

export default WhatImDoingNow;
