// NotFound.js
import React, { useEffect } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import CrazySVG from "./crazierSVG";
import styles from "./NotFound.module.css";

const NotFound = () => {
  // Detect Safari for potential fallback
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.documentElement.classList.add("safari");
    }
  }, []);

  const { scrollY } = useScroll();

  // Subtle vertical translation and rotation
  const y = useTransform(scrollY, [0, 300], [0, 50]);
  const rotate = useTransform(scrollY, [0, 900], [0, 5]);

  // Smooth out the animation transitions
  const smoothY = useSpring(y, { damping: 20, stiffness: 100 });
  const smoothRotate = useSpring(rotate, { damping: 0, stiffness: 100 });

  return (
    <>
      {/* Include the SVG filter definitions for CRT effect */}
      <CrazySVG />

      <motion.div
        className={styles.notFoundContainer}
        style={{ y: smoothY, rotate: smoothRotate }}
      >
        <h2 className={styles.title}>404: Page Not Found</h2>
        <p className={styles.text}>
          It seems you've pressed a button leading to a page that doesn't exist.
        </p>
        <p className={styles.text}>
          Please select a valid page from the monitor buttons.
        </p>
      </motion.div>
    </>
  );
};

export default NotFound;
