// MonitorFrame.js
import React, { useState } from "react";
import "../styles/MonitorFrame.css";

// Page components
import Portfolio from "./Portfolio";
import Now from "./RightNow";
import NotFound from "./NotFound";
import NotFoundCrazy from "./404Crazy";
import TurningOff from "./TurningOff";
import TurningOn from "./TurningOn";

const MonitorFrame = () => {
  const [currentPage, setCurrentPage] = useState("portfolio");
  const [isMonitorOn, setIsMonitorOn] = useState(true);

  // Updated handleButtonClick: do nothing if the monitor is off
  const handleButtonClick = (pageName) => {
    if (!isMonitorOn) return;
    setCurrentPage(pageName);
  };

  // Power button handler toggles monitor state
  const handlePowerButton = () => {
    console.log("Power button clicked. isMonitorOn:", isMonitorOn);
    if (isMonitorOn) {
      // Turn off monitor
      setCurrentPage("off");
      setIsMonitorOn(false);
    } else {
      // Turn on monitor: start boot sequence
      setCurrentPage("turningon");
      // isMonitorOn will be set to true in handleBootComplete
    }
  };

  const handleBootComplete = () => {
    console.log("Boot complete.");
    setCurrentPage("portfolio");
    setIsMonitorOn(true);
  };

  return (
    <div className="monitor-wrapper">
      <div className="monitor">
        <img
          className="monitor-img"
          src="/assets/monitor.png"
          alt="Monitor Frame"
        />

        <div className="monitor-button-container">
          <button
            className="monitor-button"
            style={{ top: "511px", left: "249px" }}
            onClick={() => handleButtonClick("portfolio")}
            aria-label="Portfolio Page"
          />
          <button
            className="monitor-button"
            style={{ top: "511px", left: "279px" }}
            onClick={() => handleButtonClick("projects")}
            aria-label="Projects Page"
          />
          <button
            className="monitor-button"
            style={{ top: "511px", left: "304px" }}
            onClick={() => handleButtonClick("404")}
            aria-label="Camera Page"
          />
          <button
            className="monitor-button"
            style={{ top: "511px", left: "333px" }}
            onClick={() => handleButtonClick("404crazy")}
            aria-label="Gallery Page"
          />

          {/* Power button always active */}
          <button
            className={`monitor-button power-button ${isMonitorOn ? "on" : "off"}`}
            style={{ top: "502px", left: "443px" }}
            onClick={handlePowerButton}
            aria-label="Power Button"
          />
        </div>

        <div className="screen">
          <div className="scrollContent">
            {currentPage === "portfolio" && <Portfolio />}
            {currentPage === "projects" && <Now />}
            {currentPage === "404" && <NotFound />}
            {currentPage === "404crazy" && <NotFoundCrazy />}
            {currentPage === "off" && <TurningOff />}
            {currentPage === "turningon" && (
              <TurningOn onBootComplete={handleBootComplete} />
            )}
          </div>
          <div className="crtOverlay"></div>
        </div>
      </div>

      <div className="contact-attach">
        <h1 className="nameHeader">MATTHEW PORTEOUS</h1>
        <div className="contactInfo">
          <a
            href="https://www.linkedin.com/in/matthew-porteous-23847a2b2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
            <img src="/assets/arrowupright.svg" alt="arrow" className="arrow" />
          </a>
          <a
            href="https://github.com/Torteous44"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
            <img src="/assets/arrowupright.svg" alt="arrow" className="arrow" />
          </a>
          <a href="mailto:mattporteous44@gmail.com">
            Email
            <img src="/assets/arrowupright.svg" alt="arrow" className="arrow" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MonitorFrame;
