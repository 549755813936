// SVGFilters.js
import React, { useEffect, useState } from "react";

// Ease-out cubic function: f(t) = 1 - (1 - t)^3
const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

const SVGFilters = ({ baseFrequency = 0.005, initialScale = 900, finalScale = 8, duration = 500 }) => {
  const [scale, setScale] = useState(initialScale);

  useEffect(() => {
    let start = null;

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      // Calculate progress ratio (0 to 1)
      const progressRatio = Math.min(progress / duration, 1);
      // Apply the ease-out cubic easing function
      const easedValue = easeOutCubic(progressRatio);
      // Interpolate between initialScale and finalScale using the eased value
      const currentScale = initialScale + (finalScale - initialScale) * easedValue;
      setScale(currentScale);
      if (progress < duration) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [initialScale, finalScale, duration]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      style={{ position: "absolute", width: 0, height: 0 }}
    >
      <filter id="crtCurved" filterUnits="objectBoundingBox" x="0" y="0" width="1" height="1">
        <feTurbulence 
          type="fractalNoise" 
          baseFrequency={baseFrequency} 
          numOctaves="6" 
          result="noise"
        />
        <feDisplacementMap 
          in="SourceGraphic" 
          in2="noise" 
          scale={scale} 
          xChannelSelector="R" 
          yChannelSelector="G" 
        />
      </filter>
    </svg>
  );
};

export default SVGFilters;
