// TurningOff.js
import React, { useEffect } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import SVGFilter from "./SVGFilter";
import styles from "./TurningOff.module.css";

const TurningOff = () => {
  // Detect Safari for potential fallback
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.documentElement.classList.add("safari");
    }
  }, []);

  const { scrollY } = useScroll();

  // Create a subtle vertical translation and rotation
  const y = useTransform(scrollY, [0, 300], [0, 50]);
  const rotate = useTransform(scrollY, [0, 900], [0, 5]);

  // Smooth out the animation transitions
  const smoothY = useSpring(y, { damping: 20, stiffness: 100 });
  const smoothRotate = useSpring(rotate, { damping: 0, stiffness: 100 });

  return (
    <>
      {/* Render the SVG filter definitions */}
      <SVGFilter />

      <motion.div
        className={styles.offContainer}
        style={{ y: smoothY, rotate: smoothRotate }}
      >
        {/* Nothing but a black screen behind the CRT filter */}
      </motion.div>
    </>
  );
};

export default TurningOff;
