// Portfolio.js
import React, { useEffect } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import SVGFilter from "./SVGFilter";
import styles from "./Portfolio.module.css";

const Portfolio = () => {
  // Detect Safari and add a class for potential fixes
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.documentElement.classList.add("safari");
    }
  }, []);

  const { scrollY } = useScroll();

  // Create a vertical translation that moves from 0 to 50px as you scroll from 0 to 300px
  const y = useTransform(scrollY, [0, 300], [0, 50]);
  // Create a subtle rotation that goes from 0 to 5 degrees as you scroll from 0 to 900px
  const rotate = useTransform(scrollY, [0, 900], [0, 5]);

  // Use springs to smooth out the animation transitions
  const smoothY = useSpring(y, { damping: 20, stiffness: 100 });
  const smoothRotate = useSpring(rotate, { damping: 0, stiffness: 100 });

  return (
    <>
      {/* Render the SVG filter definitions */}
      <SVGFilter />

      <motion.div
        className={styles.portfolioContainer}
        style={{ y: smoothY, rotate: smoothRotate }}
      >
        {/* Optional central outline element */}
        <div className={styles.centralOutline}></div>

        {/* About Me Section */}
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>ABOUT ME</h2>
          <p className={styles.text}>
            I'm a full stack developer focused on intuitive user experiences.
            Currently studying Computer Science &amp; AI at IE University in Madrid.
          </p>
        </section>

        {/* Projects Section */}
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>PROJECTS</h2>
          <div className={styles.projectsGrid}>
            <div className={styles.projectCard}>
              <h3 className={styles.projectTitle}>Thumbsy.xyz</h3>
              <p className={styles.projectDesc}>
                AI-powered product recommendation tool.
              </p>
              <a
                href="https://thumbsy.xyz"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.projectLink}
              >
                Visit
              </a>
            </div>
            <div className={styles.projectCard}>
              <h3 className={styles.projectTitle}>
                Restaurant Menu Management System
              </h3>
              <p className={styles.projectDesc}>
                AI-powered system for managing restaurant menus.
              </p>
              <a
                href="https://github.com/Torteous44/Restaurant_Menu_DB"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.projectLink}
              >
                GitHub
              </a>
            </div>
            <div className={styles.projectCard}>
              <h3 className={styles.projectTitle}>
                Terminal Chat Application in C
              </h3>
              <p className={styles.projectDesc}>
                A real-time multi-client chat application.
              </p>
              <a
                href="https://github.com/Torteous44/TerminalChat"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.projectLink}
              >
                GitHub
              </a>
            </div>
            <div className={styles.projectCard}>
              <h3 className={styles.projectTitle}>Expense Tracker</h3>
              <p className={styles.projectDesc}>
                Full-stack expense management application.
              </p>
              <a
                href="https://github.com/Torteous44/ExpenseTracker"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.projectLink}
              >
                GitHub
              </a>
            </div>
          </div>
        </section>

        {/* Skills Section */}
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>SKILLS</h2>
          <div className={styles.skillsGrid}>
            <div className={styles.skillCategory}>
              <h3 className={styles.skillCategoryTitle}>Languages</h3>
              <ul className={styles.skillsList}>
                <li>Python</li>
                <li>JavaScript</li>
                <li>C</li>
                <li>SQL</li>
              </ul>
            </div>
            <div className={styles.skillCategory}>
              <h3 className={styles.skillCategoryTitle}>Frontend</h3>
              <ul className={styles.skillsList}>
                <li>React</li>
                <li>CSS</li>
                <li>Next.js</li>
              </ul>
            </div>
            <div className={styles.skillCategory}>
              <h3 className={styles.skillCategoryTitle}>Backend</h3>
              <ul className={styles.skillsList}>
                <li>Node.js</li>
                <li>FastAPI</li>
                <li>MongoDB</li>
                <li>PostgreSQL</li>
              </ul>
            </div>
            <div className={styles.skillCategory}>
              <h3 className={styles.skillCategoryTitle}>Tools</h3>
              <ul className={styles.skillsList}>
                <li>Git</li>
                <li>Docker</li>
                <li>AWS</li>
                <li>Azure</li>
              </ul>
            </div>
          </div>
        </section>
      </motion.div>
    </>
  );
};

export default Portfolio;
