// TurningOn.js
import React, { useEffect, useState } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import BootingupSVG from "./BootingUpSVG.js"; // Renamed import
import styles from "./TurningOn.module.css";

const TurningOn = ({ onBootComplete }) => {
  const [phase, setPhase] = useState("black");

  const biosText = `
NVIDIA GeForce RTX 2060 SUPER VGA BIOS v4579
Version 90.06.41.00.51
Copyright (C) 1996-2019 NVIDIA Corp.
`;

  // Detect Safari
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.documentElement.classList.add("safari");
    }
  }, []);

  // Boot sequence timing: 1s black screen, then 3s BIOS text (total 4s)
  useEffect(() => {
    const timer1 = setTimeout(() => setPhase("bios"), 1000);
    const timer2 = setTimeout(() => {
      setPhase("done");
      onBootComplete();
    }, 4000);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [onBootComplete]);

  // Motion effects for CRT style
  const { scrollY } = useScroll();
  const yRange = useTransform(scrollY, [0, 300], [0, 50]);
  const rotateRange = useTransform(scrollY, [0, 900], [0, 5]);
  const smoothY = useSpring(yRange, { damping: 20, stiffness: 100 });
  const smoothRotate = useSpring(rotateRange, { damping: 0, stiffness: 100 });

  return (
    <>
      <BootingupSVG />
      <motion.div
        className={styles.turnOnContainer}
        style={{ y: smoothY, rotate: smoothRotate }}
      >
        {phase === "black" && <div className={styles.blackScreen} />}
        {phase === "bios" && (
          <div className={styles.biosScreen}>
            <pre className={styles.biosText}>{biosText}</pre>
            <span className={styles.blinkingCursor}>_</span>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default TurningOn;
